import axios from 'axios';
import {deleteCookie, getCookie} from 'cookies-next';

let baseURL = process.env.NEXT_PUBLIC_API_BASE_URL ?? 'https://cob.cornhub.website';
const client = axios.create({
    baseURL: '/api'
});

export default client;

export const API_NO_AUTHORIZATION = axios.create({
    baseURL: '/api'
});

export function getAPIBaseUrl() {
    return baseURL;
}

const interceptor = async (config, injectAuth) => {
    if (!baseURL) {
        console.warn('API baseURL not set, fetching from /api.json. This should not happen!')
        try {
            const response = await fetch('/api.json');
            const data = await response.json();
            baseURL = data.base;
        } catch (e) {
            baseURL = '/api';
        }
    }

    config.baseURL = baseURL || '/api';

    if (injectAuth) {
        let auth = getCookie('authentication', {req: config.req});
        config.headers = {
            Authorization: auth ? `Bearer ${auth}` : undefined,
        };
    }

    return config;
};
client.interceptors.request.use((config) => interceptor(config, true), (error) => Promise.reject(error));
client.interceptors.response.use((response) => {
    response.errored = false;

    return response;
}, (error) => {
    if (error.response && error.response.status === 401) {
        deleteCookie('authentication');
    }

    error.status = error.response?.status;
    error.errored = true;

    return error;
});
API_NO_AUTHORIZATION.interceptors.request.use((config) => interceptor(config, false), (error) => Promise.reject(error));
API_NO_AUTHORIZATION.interceptors.response.use((response) => {
    response.errored = false;

    return response;
}, (error) => {
    error.status = error.response?.status;
    error.errored = true;

    return error;
});
