import {UserFlags} from '../shared/bitsets';
import hash from 'object-hash';

export function getBannerURL(user) {
    if (user.banner === null || user.banner === undefined) {
        return 'https://cornhub.objectdepot.net/static/default_banner.jpg';
    }

    if ((user.flags & UserFlags.ExternalBanner) !== 0) {
        return user.banner;
    }

    return `https://cornhub.objectdepot.net/banner/${user.banner}`;
}

export function getProfilePictureURL(user) {
    if (user.profile_picture === null || user.profile_picture === undefined) {
        return 'https://cornhub.objectdepot.net/static/default_profile.jpg';
    }

    if ((user.flags & UserFlags.ExternalProfilePicture) !== 0) {
        return user.profile_picture;
    }

    return `https://cornhub.objectdepot.net/profilepicture/${user.profile_picture}`;
}

export function UserBadge({user, className}) {
    if ((user.flags & UserFlags.Staff) !== 0) {
        return <img src="https://cornhub.objectdepot.net/static/badges/staff.svg" width={14} height={14}
                    className={className}
                    alt="Staff Badge" title="Staff"/>;
    }

    if ((user.flags & UserFlags.Verified) !== 0) {
        return <img src="https://cornhub.objectdepot.net/static/badges/verified.svg" width={14} height={14}
                    className={className}
                    alt="Verified Badge" title="Verified"/>;
    }

    if ((user.flags & UserFlags.Premium) !== 0) {
        return <img src="https://cornhub.objectdepot.net/static/badges/premium.svg" width={14} height={14}
                    className={className}
                    alt="Premium Badge" title="Premium"/>;
    }

    return null;
}

export function getIdentity() {
    let identity = {};
    if (typeof (window) !== 'undefined') {
        identity = {
            ua: navigator.userAgent
        };
    }
    // TODO: generate identity server side

    identity.hash = hash(identity);
    identity.hashNumerical = parseInt(identity.hash, 16);

    return identity;
}
