import {pigLatinTranslate} from './pigLatin';
import ar from './languages/ar.json';
import arr from './languages/arr.json';
import da from './languages/da.json';
import de from './languages/de.json';
import el from './languages/el.json';
import en from './languages/en.json';
import es from './languages/es.json';
import fr from './languages/fr.json';
import it from './languages/it.json';
import la from './languages/la.json';
import lt from './languages/lt.json';
import nl from './languages/nl.json';
import pl from './languages/pl.json';
import pt from './languages/pt.json';
import sv from './languages/sv.json';
import vi from './languages/vi.json';
import zh from './languages/zh.json';
import {useRouter} from 'next/router';

const languages = {
    ar,
    arr,
    da,
    de,
    el,
    en,
    es,
    fr,
    it,
    la,
    lt,
    nl,
    pl,
    pt,
    sv,
    vi,
    zh,
    piglatin: pigLatinTranslate(en),
};

export function useI18NStringProvider() {
    const {locale} = useRouter();

    return (key, placeholders = {}) => {
        let s = languages[locale]?.[key] ?? languages['en']?.[key] ?? key;

        for (let [key, value] of Object.entries(placeholders)) {
            s = s.replace(`{${key}}`, value);
        }

        return s;
    };
}

export default languages;
