import languages from './i18n';
import reactStringReplace from 'react-string-replace';
import {useRouter} from 'next/router';

export function I18nText({id, placeholders = {}, boldClass = ''}) {
    const {locale} = useRouter();

    let s = languages[locale]?.[id] ?? languages['en']?.[id] ?? id;

    for (let [key, value] of Object.entries(placeholders)) {
        s = s.replace(`{${key}}`, value);
    }

    s = reactStringReplace(s, /\n/g, (match) => (
        <><br/>{match}</>
    ));

    s = reactStringReplace(s, /\[b]([^[]*)\[\/b]/g, (match) => (
        <b className={boldClass}>{match}</b>
    ));

    return s;
}
