import experiments from '../shared/experiments';
import {useDispatch, useSelector} from 'react-redux';
import {UserFlags} from '../shared/bitsets';
import {getProfilePictureURL} from '../util/userUtil';
import {useState} from 'react';
import Link from 'next/link';
import {deleteCookie} from 'cookies-next';
import {useRouter} from 'next/router';
import {I18nText} from '../i18n/I18nText';
import {LanguagePicker} from '../components/LanguagePicker';
import {useI18NStringProvider} from '../i18n/i18n';

export default function Header() {
    const activeCategory = useSelector((store) => store.navbar.activeCategory);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const [showLinks, setShowLinks] = useState(false);

    let logo;
    switch (experiments['2022_01_01_Conditional_Logos'].getValue()) {
        case 'pride':
            logo = 'https://cornhub.objectdepot.net/static/logo_transparent_pride_black_hub.png';
            break;
        case 'default':
        default:
            logo = 'https://cornhub.objectdepot.net/static/logo_transparent_black_hub.png';
            break;
    }

    return <header className="header">
        <div className="header-top grid gap-10px m-auto justify-items-center items-center">
            <div className="flex">
                <div className="sm:hidden">
                    <button onClick={() => setShowLinks(!showLinks)}
                            className="bg-no-repeat bg-contain bg-center w-30px h-30px mt-10px"
                            style={{backgroundImage: 'url(https://cornhub.objectdepot.net/static/icons/hamburger.svg)'}}/>
                </div>
                <Link href="/"><img src={logo} alt="CornHub Logo" width="150" height="26"/></Link>
            </div>
            <div className="hidden sm:block">
                <Search/>
            </div>
            <div className="hidden sm:block">
                <UserComponent/>
            </div>
        </div>
        <div className="header-bottom">
            <div
                className={`header-links h-full m-auto items-center sm:grid-flow-col sm:auto-cols-auto ${!showLinks ? 'hidden' : 'grid'} sm:grid`}>
                <div className={addActiveIfActive(activeCategory, 'home', 'header-link')}>
                    <Link href="/"><I18nText id="header.home"/></Link>
                </div>
                <div className={addActiveIfActive(activeCategory, 'videos', 'header-link')}>
                    <Link href="/"><I18nText id="header.corn_videos"/></Link>
                </div>
                <div className={addActiveIfActive(activeCategory, 'cornstars', 'header-link')}>
                    <Link href="/model"><I18nText id="header.cornstars"/></Link>
                </div>
                <div className="header-link">
                    <a href="https://go.cornhub.website/discord?utm_source=network_bar" rel="noreferrer"
                       target="_blank"><I18nText id="header.community"/></a>
                </div>
                {experiments['2022_10_28_Watch_Together'].getValue(user) ?
                    <button onClick={() => dispatch({type: 'watchtogether:opened', val: true})} className="header-link">
                        <I18nText id="header.watch_together"/>
                    </button> : null}
                <div className="sm:hidden">
                    {experiments['2024_01_03_i18n'].getValue(user) ?
                        <div className="text-center font-bold mt-1 mb-1 text-primary">
                            <I18nText id="header.change_language"/>
                            <LanguagePicker
                                className="ml-2 bg-transparent text-white border-2 border-primary p-1 rounded"/>
                        </div> : null}
                    <div>
                        <MobileUserComponent/>
                    </div>
                </div>
            </div>
        </div>
    </header>;
}

function addActiveIfActive(activeCategory, category, classes) {
    if (activeCategory === category) {
        return `${classes} active`;
    }
    return classes;
}

function Search() {
    const [query, setQuery] = useState('');
    const router = useRouter();
    const i18n = useI18NStringProvider();

    return <form action="/search" onSubmit={(e) => {
        e.preventDefault();
        router.push(`/search?q=${encodeURIComponent(query)}`);
    }}>
        <input type="text" className="text-white bg-dark p-2 rounded focus:border-1 focus:border-primary"
               placeholder={i18n('header.search')} value={query}
               onChange={(e) => setQuery(e.target.value)} name="q" minLength={1} required/>
    </form>;
}

function UserComponent() {
    const user = useSelector((state) => state.user);

    if (user === undefined) {
        return <div className="animate-pulse flex items-center text-gray-800">
            <div className="w-10 h-10 bg-gray-800"/>
            <span className="pl-2">&#9660;</span>
        </div>;
    }

    if (user === null) {
        return <div className="text-zinc-300 grid grid-cols-1 md:grid-cols-2 gap-1">
            <Link href="/login" className="hover:text-white"><I18nText id="header.login"/></Link>
            <Link href="/login" className="hover:text-white"><I18nText id="header.signup"/></Link>
        </div>;
    }

    return <div>
        <div className="flex items-center cursor-pointer peer">
            <img src={getProfilePictureURL(user)}
                 alt="Your Profile Icon" width={39} height={39}/>
            <span className="pl-2">&#9660;</span>
        </div>
        <div className="hidden peer-hover:flex hover:flex text-white fixed z-50">
            <div className="bg-gray-800 mt-2 flex flex-col">
                <p className="p-2"><I18nText id="header.logged_in" placeholders={{username: user.username}}
                                             boldClass="text-primary"/></p>
                {(user.flags & UserFlags.ExperimentsInUserMenu) !== 0 ?
                    <Link href="/experiments-i-know-what-im-doing-i-swear"
                          className="p-2 hover:bg-gray-700 active:bg-gray-600"><I18nText
                        id="header.experiments"/></Link> : null}
                <Link href="/settings" className="p-2 hover:bg-gray-700 active:bg-gray-600"><I18nText
                    id="header.settings"/></Link>
                <button className="p-2 hover:bg-gray-700 active:bg-gray-600 text-left" onClick={() => {
                    deleteCookie('authentication');
                    window.location.reload();
                }}>
                    <I18nText id="header.logout"/>
                </button>
            </div>
        </div>
    </div>;
}

function MobileUserComponent() {
    const user = useSelector((state) => state.user);

    if (user === undefined) {
        return <div className="animate-pulse header-link">
            ...
        </div>;
    }

    if (user === null) {
        return <Link href="/login" className="header-link"><I18nText id="header.login_or_signup"/></Link>;
    }

    return <div className="header-link border-t-1 border-primary border-opacity-50">
        <div className="flex pl-5 pr-5 m-auto">
            <img src={getProfilePictureURL(user)}
                 alt="Your Profile Icon" width={32} height={32}/>
            <span className="text-primary pl-2 mb-auto mt-auto">{user.display_name ?? user.username}</span>
            <div className="w-full mt-auto mb-auto">
                <Link href="/settings"><I18nText id="header.settings"/></Link>
                <button className="float-right font-bold uppercase" onClick={() => {
                    deleteCookie('authentication');
                    window.location.reload();
                }}>
                    <I18nText id="header.logout"/>
                </button>
            </div>
        </div>
    </div>;
}
