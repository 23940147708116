const AdFlags = {
    ExternalImage: (1 << 0)
};

const CommentFlags = {
    Deleted: (1 << 0)
};

const UserFlags = {
    Staff: (1 << 0),
    Verified: (1 << 1),
    ExternalProfilePicture: (1 << 2),
    Premium: (1 << 3),
    ExperimentsInUserMenu: (1 << 4),
    InDiscordServer: (1 << 5),
    ExternalBanner: (1 << 6)
};

const ThumbnailFlags = {
    LQ: (1 << 0),
    HQ: (1 << 1),
    Animated: (1 << 2)
};

const QualityFlags = {
    P360: (1 << 0),
    P480: (1 << 2),
    P720: (1 << 3),
    P1080: (1 << 4),
    P2160: (1 << 5)
};

const VideoFlags = {
    SupportsPartials: (1 << 0)
};

module.exports = {
    AdFlags,
    CommentFlags,
    UserFlags,
    ThumbnailFlags,
    QualityFlags,
    VideoFlags
};
