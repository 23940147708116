import languages from '../i18n/i18n';
import {useRouter} from 'next/router';
import {getCookie} from 'cookies-next';

const localeFlags = {
    ar: '🇦🇪',
    arr: '🏴‍☠️',
    da: '🇩🇰',
    de: '🇩🇪',
    el: '🇬🇷',
    en: '🇬🇧',
    es: '🇪🇸',
    fr: '🇫🇷',
    it: '🇮🇹',
    la: '🇻🇦',
    lt: '🇱🇹',
    nl: '🇳🇱',
    pl: '🇵🇱',
    pt: '🇵🇹',
    sv: '🇸🇪',
    vi: '🇻🇳',
    zh: '🇨🇳',
    piglatin: '🐷'
};

export function LanguagePicker({className}) {
    const router = useRouter();

    return <select className={className} value={router.locale} onChange={(e) => {
        let newLocale = e.target.value;
        let newDomain = `${newLocale}.cornhub.website`;
        if (newLocale === 'en') {
            newDomain = 'cornhub.website';
        }
        let extraParam = '';
        let auth = getCookie('authentication')
        if (auth) {
            if (Object.keys(router.query).length !== 0) {
                extraParam = `&authorization=${encodeURIComponent(auth)}`;
            } else {
                extraParam = `?authorization=${encodeURIComponent(auth)}`;
            }
        }

        window.location.href = `https://${newDomain}${router.asPath}${extraParam}`;
    }}>
        {Object.entries(localeFlags)
            .filter((l) => l[0] !== 'piglatin' || router.locale === 'piglatin')
            .map(([localeCode, flag]) => <option key={localeCode} value={localeCode} className="text-black">
                {flag} {languages[localeCode]['locale.self.name']}
            </option>)}
    </select>;
}
