import Link from 'next/link';
import {I18nText} from '../i18n/I18nText';

export default function Footer() {
    return <div className="mt-20" id="footer">
        <p className="ml-8 mr-8 text-gray-400 text-center">
            <I18nText id="footer.seo"/>
        </p>

        <footer>
            <div className="footer mt-5 min-h-200px p-3">
                <div className="grid grid-cols-2 md:grid-cols-4 m-auto max-w-1300px">
                    <div>
                        <h3 className="text-lg font-semibold text-gray-400"><I18nText
                            id="footer.category.information"/></h3>
                        <ul className="text-primary leading-6">
                            <li><Link href="/legal/terms"><I18nText id="footer.information.terms"/></Link></li>
                            <li><Link href="/legal/privacy"><I18nText id="footer.information.privacy"/></Link></li>
                            <li><Link href="/legal/fairuse"><I18nText id="footer.information.fairuse"/></Link></li>
                            <li>
                                <div id="ccpa" style={{
                                    display: 'none',
                                    cursor: 'pointer'
                                }}>
                                    Do not share my Personal Information.
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold text-gray-400"><I18nText
                            id="footer.category.work_with_us"/></h3>
                        <ul className="text-primary leading-6">
                            <li><Link href="/contact/advertise"><I18nText id="footer.work_with_us.advertise"/></Link>
                            </li>
                            <li><a href="https://crowdin.com/project/cornhub" rel="noreferrer" target="_blank"><I18nText
                                id="footer.work_with_us.translate"/></a></li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold text-gray-400"><I18nText
                            id="footer.category.support_and_help"/></h3>
                        <ul className="text-primary leading-6">
                            <li><Link href="/contact"><I18nText id="footer.support_and_help.contact"/></Link></li>
                            <li><a href="https://go.cornhub.website/appeals?utm_source=footer" target="_blank"
                                   rel="noreferrer"><I18nText id="footer.support_and_help.discord_appeals"/></a></li>
                        </ul>
                    </div>
                    <div>
                        <h3 className="text-lg font-semibold text-gray-400"><I18nText
                            id="footer.category.discover"/></h3>
                        <ul className="text-primary leading-6">
                            <li><a href="https://discord.gg/b3ZCsWkE2G" target="_blank" rel="noreferrer"><I18nText
                                id="social.discord"/></a>
                            </li>
                            <li><a href="https://instagram.com/cornhub.website" target="_blank"
                                   rel="noreferrer"><I18nText
                                id="social.instagram"/></a></li>
                            <li><a href="https://tiktok.com/@cornhub.website" target="_blank"
                                   rel="noreferrer"><I18nText
                                id="social.tiktok"/></a></li>
                            <li><a href="https://twitter.com/cornhubwebsite" target="_blank"
                                   rel="noreferrer"><I18nText
                                id="social.twitter"/></a></li>
                            <li><a href="https://youtube.com/@cornhub.website" target="_blank"
                                   rel="noreferrer"><I18nText
                                id="social.youtube"/></a></li>
                        </ul>
                    </div>
                </div>
                <p className="text-center mt-3 text-gray-400">
                    CornHub is <a href="https://tree-nation.com/profile/impact/frumentum#co2" rel="noreferrer"
                                  target="_blank" className="text-gray-300 underline">a CO2 Net Zero</a> website
                </p>
            </div>
            <div className="bg-black">
                <div className="m-auto max-w-1300px">
                    <div className="float-left m-2">
                        <a href="https://go.cornhub.website/discord?utm_source=footer" rel="noreferrer" target="_blank">
                            <img src="https://cornhub.objectdepot.net/static/icons/discord.png"
                                 className="rounded-full bg-slate-600 hover:bg-slate-700 p-10px" alt="Discord Logo"
                                 width={40}/>
                        </a>
                    </div>
                    <p className="float-right m-5">&copy; CornHub.website, {new Date().getFullYear()}</p>
                </div>
            </div>
        </footer>
    </div>;
}
