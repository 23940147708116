import API from '../API';
import Link from 'next/link';
import {useRouter} from 'next/router';

export function PolicyUpdatedModal({privacy = false, terms = false, user}) {
    const router = useRouter();

    let path = router.pathname.toLowerCase();
    if (path.startsWith('/contact') || path.startsWith('/legal') || path.startsWith('/settings')) return null;

    async function agree() {
        await API.post('/user/@me/legal');
        window.location.reload();
    }

    return <div className="fixed left-0 right-0 top-0 bottom-0 z-50 bg-black bg-opacity-80 p-5 md:p-10 w-screen">
        <div className="m-auto bg-black p-5 border-1 border-primary rounded max-w-600px">
            <h1 className="text-3xl text-center font-bold">We've updated
                our {privacy && terms ? <>policies</> : (privacy ? <>privacy policy</> : <>terms of service</>)}!</h1>
            <p className="text-lg">
                Hey <b className="text-primary">{user.display_name ?? user.username}</b>! We've made some changes to our
                {privacy ? <> <Link className="text-primary underline" href="/legal/privacy" target="_blank">privacy
                    policy</Link> </> : null}
                {privacy && terms ? <>and</> : null}
                {terms ? <> <Link className="text-primary underline" href="/legal/terms"
                                  target="_blank">terms of service</Link> </> : null}
                since the last time you visited.
            </p>
            <p className="text-lg">
                If you agree and want to continue having a corny time, click the button below
            </p>
            <br/>
            <div>
                <button onClick={() => agree()}
                        className="w-full text-3xl text-primary border-1 border-slate-600 p-2 rounded transition font-semibold hover:text-white hover:bg-green-500 active:text-white active:bg-green-600">
                    I agree, let me in
                </button>
            </div>
            <br/>
            <Link href="/settings"
                  className="w-full block text-center text-lg text-red-200 border-1 border-slate-600 p-2 rounded transition hover:text-white hover:bg-red-500 active:text-white active:bg-red-600">
                I disagree, delete my account
            </Link>
        </div>
    </div>;
}
