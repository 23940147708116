const consonantRegex = /^[^aeiou]+/;

export function pigLatinTranslate(input) {
    let output = {};

    for (const [key, value] of Object.entries(input)) {
        output[key] = value.split(' ').map((word) => {
            // https://forum.freecodecamp.org/t/freecodecamp-challenge-guide-pig-latin/16039
            let myConsonants = word.match(consonantRegex);
            return myConsonants !== null
                ? word
                    .replace(consonantRegex, '')
                    .concat(myConsonants)
                    .concat('ay')
                : word.concat('way');
        }).join(' ');
    }

    return output;
}
