export function rootReducer(state, {type, val}) {
    const newState = {...state};

    // namespace:action

    switch (type) {
        case 'currentPage:set':
            newState.previousPage = state.currentPage;
            newState.currentPage = val;
            break;

        case 'navbar:setActiveCategory':
            newState.navbar = {
                ...state.navbar,
                activeCategory: val
            };
            break;

        case 'user:set':
            newState.user = val;
            break;

        case 'watchtogether:opened':
            newState.watchTogether.opened = val;
            break;

        default:
            break;
    }

    return newState;
}
