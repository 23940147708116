import {useEffect, useState} from 'react';
import {I18nText} from '../i18n/I18nText';

export default function Loader({timeout = 5000}) {
    let [messageId, setMessageId] = useState(0);
    let [loadingTooLong, setLoadingTooLong] = useState(false);

    useEffect(() => {
        setMessageId(Math.floor(Math.random() * 6));

        if (timeout <= 0) return;

        let task = setTimeout(() => {
            setLoadingTooLong(true);
        }, timeout);

        return () => clearTimeout(task);
    }, []);

    return <div className="w-full">
        <div className="m-auto mt-3">
            <img src="https://cornhub.objectdepot.net/static/loading.gif" className="max-w-screen m-auto p-2"
                 alt="Loading screen"/>
            <h1 className="text-2xl text-center font-bold p-2"><I18nText id={`loader.${messageId}`}/></h1>
            {loadingTooLong ? <h2 className="text-lg text-center text-slate-400 p-2">
                <I18nText id="loader.too_long.title"/>
                <br/>
                <I18nText id="loader.too_long.instruction"/>
            </h2> : null}
        </div>
    </div>;
}
